<template>
  <div class="sub-users">
    <div class="top-btns">
      <el-button type="primary" icon="el-icon-plus" @click="doAdd" size="mini" v-power="`1000-10-10`"
        >新增</el-button
      >
    </div>
    <!-- 查询表单 -->
    <div class="search-form">
      <el-form inline size="mini">
        <el-form-item label="姓名">
          <el-input v-model="searchForm.nameLike" clearable />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchForm.phoneLike" clearable />
        </el-form-item>
        <el-form-item label="状态">
          <code-domain-select v-model="searchForm.flag" code-domain="domain.flag" ></code-domain-select>
        </el-form-item>
        <el-form-item label="创建人" >
          <code-autocomplate v-model="searchForm.createdBy" code-domain="cmsUser-domain" ></code-autocomplate>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 用户列表 -->
    <common-table ref="userTable" :apiKey="tableConfig.apiKey" :autoLoad="tableConfig.autoLoad" :search-params="searchForm" :columns="tableConfig.columns" :options="tableConfig.options" />

    <el-dialog
      :title="editDialogInfo.title"
      :visible.sync="editDialogInfo.show"
      :close-on-click-modal="false"
      @opened="subUserEditDialogOpened"
      @closed="subUserEditDialogCloesd"
    >
      <sub-user-edit-dialog
        ref="subUserEditDialog"
        :action="editDialogInfo.action"
        :id="editDialogInfo.subUserId"
      />
      <template #footer>
        <div class="sub-user-edit-bottom-toolbar">
          <el-button
            type="primary"
            size="mini"
            :loading="saving"
            @click="saveSubUser"
            >保存</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cms-sub-users",
  data() {
    return {
      saving: false,
      searchForm: {},
      tableConfig: {
        apiKey: 'fw-cms-user-findUser',
        autoLoad: true,
        columns: [{
          label: '姓名',
          prop: 'name'
        },{
          label: '手机号',
          prop: 'phone'
        }, {
          label: '状态',
          prop: '_caption.flag'
        }, {
          label: '角色',
          prop: '_caption.roleIds'
        }, {
          label: '创建人',
          prop: '_caption.createdBy'
        }, {
          label: '创建时间',
          prop: 'createdTime'
        }, {
          label: '修改人',
          prop: '_caption.updatedBy'
        }, {
          label: '修改时间',
          prop: 'updatedTime'
        }],
        options: [{
          label: '修改',
          powerId: '1000-10-20',
          handler: (row) => {
            this.editUserInfo(row);
          }
        }, {
          label: '停用',
          powerId: '1000-10-30',
          handler: (row) => {
            this.dropdownHandler({
              apiKey: 'fw-cms-user-disable',
              op: '停用',
              opColor: 'red',
              bean: row,
            });
          }
        }, {
          label: '启用',
          powerId: '1000-10-40',
          handler: (row) => {
            this.dropdownHandler({
              apiKey: 'fw-cms-user-enable',
              op: '启用',
              opColor: 'green',
              bean: row,
            });
          }
        }]
      },
      editDialogInfo: {
        title: "子账户编辑",
        show: false,
        action: "edit",
      },
    };
  },
  methods: {
    async dropdownHandler(cmd) {
      let _this = this;
      let { apiKey, op, bean, opColor } = cmd;
      this.$confirm(
        `将要<span style="color: ${opColor};">${op}</span>【${bean.name}】确信操作吗？`,
        "提示",
        {
          distinguishCancelAndClose: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          async beforeClose(action, instance, done) {
            if (action === "confirm") {
              await _this.$http.doApi(apiKey, { id: cmd.bean.id });
              _this.$message.success("操作成功");
              _this.$refs.userTable.refreshCurrent();
            }
            done();
          },
        }
      );
    },
    subUserEditDialogCloesd() {
      this.editDialogInfo.subUserId = null;
      this.$refs.subUserEditDialog.closed();
    },
    subUserEditDialogOpened() {
      this.$refs.subUserEditDialog.opened();
    },
    doAdd() {
      this.editDialogInfo.action = "add";
      this.editDialogInfo.title = "新增子账户";
      this.editDialogInfo.show = true;
      this.editDialogInfo.subUserId = null;
    },
    async saveSubUser() {
      try {
        this.saving = true;
        let userInfo = await this.$refs.subUserEditDialog.getFormValues();
        await this.$http.doApi("fw-cms-user-saveDetail", {}, userInfo);
        this.editDialogInfo.show = false;
        this.$refs.userTable.refreshCurrent();
      } catch (e) {
        console.log(e);
      } finally {
        this.saving = false;
      }
    },
    doSearch() {
      this.$refs.userTable.refresh();
    },
    editUserInfo(row) {
      this.editDialogInfo.action = "edit";
      this.editDialogInfo.title = "修改子账户信息";
      this.editDialogInfo.show = true;
      this.editDialogInfo.subUserId = row.id;
    }
  },
  components: {
    "sub-user-edit-dialog": () => import("./SubUserEditDialog")
  },
};
</script>

<style lang="less">
.sub-users {
  padding: 10px;

  .top-btns {
    margin-bottom: 1rem;
  }

  .search-form {
    padding: 10px;
    margin-bottom: 1rem;
  }

  .user-table {
    width: 100%;

    .el-dropdown-link {
      color: #409eff;
      cursor: pointer;
    }
  }

  .user-table-header {
    color: black;
    th {
      background-color: #f5f5f6;
    }
  }

  .pagination {
    text-align: center;
    margin-top: 1rem;
  }

  .sub-user-edit-bottom-toolbar {
    text-align: center;
  }
}
</style>